.our-service-box {
  margin-bottom: 15px;
  margin-top: 15px;
  transition: 0.5s;
}

.our-service-box:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, .15);

}