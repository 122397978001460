.address-box {
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, .15);
  padding: 25px 25px 25px 115px;
  position: relative;
  height: 100%;
}

.contact-us h4 {
  margin-top: 20px;
  line-height: 1;
}

.address-box .media-left {
  font-size: 35px;
  position: absolute;
  left: 25px;
  top: 50%;
  width: 70px;
  height: 70px;
  line-height: 68px;
  text-align: center;
  border-radius: 50%;
  color: var(--orange);
  border: 1px solid var(--orange);
  transition: .4s;
  transform: translateY(-50%);
}

.address-box:hover .media-left {
  color: var(--white);
  background-color: var(--orange);
}

.address-box .media-body {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  line-height: 22px;
}

.address-box .media-body h4 {
  line-height: 25px;
  margin-top: 0px;
  font-size: 18px;
}

.address-box p {
  margin-bottom: 0;
}

.address-box a {
  margin: 0;
  line-height: 20px;
  font-weight: 400;
  color: var(--light);
}

.address-box a:hover {
  color: var(--orange);
}

.social-icon .list-group-item {
  width: 50px;
  height: 50px;
  border: 1px solid var(--orange);
  background: var(--orange);
  color: var(--white);
  border-radius: 100%;
  line-height: 50px;
  margin-right: 10px;
  padding: 0;
  text-align: center;
}

.social-icon .list-group-item:hover {
  background: transparent;
  color: var(--orange);
}

.social-icon.list-group {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  align-items: center;
}

.social-icon .list-group-item:last-child,
.social-icon .list-group-item:first-child {
  border-radius: 100%;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: 50px;
  padding: 0.375rem 0.75rem;
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid var(--ingrey);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: #495057;
  border-color: var(--ingrey);
  outline: 0;
  box-shadow: none;
}

textarea.form-control {
  height: 90px;
  resize: none;
}

textarea.form-control {
  height: 110px;
}

.btn-primary {
  color: var(--white);
  background-color: var(--orange);
  border: 1px solid var(--orange);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: var(--orange);
  background-color: var(--white);
  border: 1px solid var(--orange);
  outline: none;
  box-shadow: none !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  color: var(--white);
  background-color: var(--black);
  box-shadow: none !important;
}