.expert-team {
  background: var(--bggrey);
}

.team-box {
  box-shadow: 7px 5px 30px rgba(72, 73, 121, .15);
  background: var(--orange);
  position: relative;
  transition: .4s;
  padding-top: 01px;
  padding: 50px 25px 25px;
  margin-top: 85px;
  border: 1px solid var(--orange);
}

.team-box p {
  margin-bottom: 20px;
}

.team-img {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  border: 1px solid var(--orange);
  margin: -131px auto 0;
}

.social-icon-team .list-group-item {
  font-size: 14px;
  color: var(--orange);
  border-radius: 0;
  margin: 0 7px;
  padding: 0;
  text-align: center;
  border: none;
}

.social-icon-team .list-group-item i {
  font-size: 20px;
}

.social-icon-team .list-group-item:hover {
  color: var(--black);
  background: transparent;

}

.social-icon-team {
  flex-direction: row;
  padding: 10px;

  align-items: center;
  background-color: var(--white);
  max-width: 170px;
  border-radius: 46px;
  justify-content: center;
  margin: 0 auto -40px;
}