/* Subscribe Section Start css */
.subscribe-box{
  background: var(--orange);
  padding: 50px 0;   
}

.news-box .form-control,
.news-box .form-control:focus {
  background-color: transparent;
  height: 50px;
  color: #fff;
  box-shadow: none;
  outline: none;
  border-color: #fff;
  border-radius: 25px;

}

/* placeholder*/
.news-box .form-control::-webkit-input-placeholder {
  /* Chrome */
  color: #fff;
}

.news-box .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.news-box .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  opacity: 1;
}

.news-box .form-control:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #fff;
  opacity: 1;
}

/* end placeholder */
.news-box .icofont-email {
  font-size: 35px;
  color: var(--orange);
  ;
  line-height: 30px;
}

.input-group-append .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 50px;
  padding: 10px 21px;
}