/* Default css */
html {
  height: 100%;
}

body {
  font-size: 16px;
  color: var(--light);
  background: var(--white);
  font-family: "Roboto", sans-serif !important;
  line-height: 1.321em;
  letter-spacing: 0.009em;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

* {
  margin: 0px;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style-type: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  display: inline-block;
  outline: 0 !important;
  text-decoration: none !important;
  transition: 0.4s;
}

a:focus {
  outline: none;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: 700;
  color: var(--black);
  line-height: 1.5;
  word-wrap: break-word;
  letter-spacing: 1px;
}

h1 {
  font-size: 42px;
  /* = 42px */
  margin-bottom: 0.5em;
}

h2 {
  font-size: 36px;
  /* = 36px */
  margin-bottom: 0.75em;
}

h3 {
  font-size: 30px;
  /* = 30px */
  margin-bottom: 0.857em;
}

h4 {
  font-size: 24px;
  /* = 24px */
  margin-bottom: 0.5rem;
}

h5 {
  font-size: 18px;
  /* = 18px */
  margin-bottom: 1.125em;
}

h6 {
  font-size: 16px;
  /* = 16px */
  margin-bottom: 1.285em;
}

p {
  margin: 0 0 20px 0;
  font-size: 15px;
  font-weight: 300;
  color: var(--light);
  line-height: 1.5;
}

button:focus {
  outline: none;
}

.grapping {
  padding: 100px 0;
}

.btn-secondary {
  background: var(--white);
  border-color: var(--white);
  font-weight: 500;
  color: var(--black);
}

.btn {
  font-weight: 700;
  font-size: 15px;
  border: none;
  padding: 10px 33px;
  line-height: 1.5;
  text-transform: capitalize;
  border-radius: 50px;
  letter-spacing: 0.5px;
}

.title-box {
  text-align: center;
  margin-bottom: 50px;
  margin-top: -8px;
}

.title-box h2 {
  font-size: 30px;
  margin-bottom: 15px;
}

.title-box p {
  font-size: 17px;
  max-width: 535px;
  margin: 0 auto;
  line-height: 1.5;
  font-weight: 400;
}

.sub-title {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0 0 15px;
}

.sub-title span {
  color: var(--orange);
}

.white-bg {
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  padding: 25px;
  background: var(--white);
  position: relative;
  transition: 0.4s;
}

.common-box i {
  font-size: 50px;
  color: var(--grey);
  background: var(--grey);
}

.common-box h4 {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 15px;
}

.common-box p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.8;
  background: var(--grey);
  margin-bottom: 0;
}

/* End  Default css */
