.banner-img {
  height: 100vh;
  overflow: hidden;

  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.banner-one {
  background: url(../images/main-banner2.jpg) no-repeat 0 0;
  background-color: #ddd;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-two {
  background: url(../images/main-banner3.jpg) no-repeat 0 0;
  background-color: #ddd;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-img:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.6);
  background-position: 50%;

  background-repeat: repeat;
  background-position: 0 top;
}

.slick-prev.custom-arrow {
  z-index: 1;
  left: 45px;
}

.slick-next.custom-arrow {
  z-index: 1;
  right: 45px;
}

.banner-text h1,
.banner-text p {
  color: var(--white);
}

h1 {
  font-size: 2.8rem;
  text-transform: uppercase;
}

.banner-text {
  padding: 0 25px;
}

.banner-text p {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.banner-text h1 span {
  color: var(--orange);
}